@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.payslip-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: utils.bu(5);

  tr .button {
    width: utils.rem(178);
  }
}
