@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/extra" as extra;
@use "../../stylesheets/00_settings/util" as utils;

.button {
  width: 100%;
  background-color: map-get(colors.$primary-color, primary);
  border-radius: map-get(extra.$border-radius, default);
  border: none;
  color: map-get(colors.$text-color, default);
  font-size: utils.rem(18);
  padding: utils.bu(2);
  font-weight: bold;
  cursor: pointer;

  &--disabled {
    opacity: .4;
    cursor: default;
    pointer-events: none;
  }

  &--secondary {
    background-color: map-get(colors.$primary-color, white);
    color: map-get(colors.$primary-color, primary);
  }

  &--hollow {
    border: utils.rem(1) solid map-get(colors.$primary-color, primary);
    color: map-get(colors.$primary-color, black);
    font-weight: normal;
    background-color: map-get(colors.$primary-color, white);

    &:hover {
      background-color: map-get(colors.$primary-color, primary);
      color: map-get(colors.$text-color, default);
    }
  }
}
