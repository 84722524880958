@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/extra" as extra;
@use "../../../stylesheets/00_settings/util" as utils;
@use "../../../stylesheets/01_tools/mixin" as mixins;

.auth-layout {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: utils.rem(75) 0;
  }

  &__title {
    font-size: utils.rem(67);
    color: map-get(colors.$primary-color, secondary);
    font-weight: 300;
    margin-bottom: utils.bu(6);
    text-align: center;
    @include mixins.set-font-family('Plantin Light');
  }

  &__subtitle {
    margin-bottom: utils.bu(6);
    font-weight: normal;
    color: map-get(colors.$text-color, subtitle);
  }

  &__form {
    width: fit-content;
    border-radius: map-get(extra.$border-radius, default);
    background-color: map-get(colors.$primary-color, white);
    padding: utils.bu(4.5);
    min-width: utils.rem(672);
  }

  &__action {
    color: map-get(colors.$primary-color, primary);
    margin-top: utils.bu(5);
  }
}
