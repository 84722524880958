@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.login {
  width: 100%;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__forgot-password {
    width: utils.rem(119);
    color: map-get(colors.$primary-color, secondary);
  }
}
