@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/util" as utils;

.header {
  width: 100%;
  background-color: map-get(colors.$primary-color, secondary);
  height: utils.rem(126);
  padding: utils.bu(4) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  $block: &;

  &__search {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      height: utils.rem(96);
    }

    .input {
      margin-top: utils.bu(2);
      margin-left: utils.bu(10);
    }
  }

  &--authenticated {
    height: utils.rem(100);
    background-color: map-get(colors.$primary-color, white);
    box-shadow: 0 utils.rem(5) utils.rem(5) #0000000d;
    justify-content: space-between;
    padding-left: utils.bu(4);
    padding-right: utils.bu(9);

    #{$block}__search {

      > svg {
        cursor: pointer;
        height: utils.rem(57);
      }
    }
  }
}
