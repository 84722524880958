@use "../../../stylesheets/00_settings/util" as utils;

.form-row {
  width: 100%;

  & + & {
    margin-top: utils.bu(4);
  }

  &--fluid {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: utils.bu(2);
    }
  }
}
