@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.authenticated-layout {
  height: 100%;
  overflow: hidden;

  > main {
    display: flex;
    height: calc(100% - 100px);
  }

  &__content {
    width: 100%;
    padding-top: utils.bu(3);
    padding-left: utils.bu(9);
    padding-bottom: utils.bu(5);
    overflow-y: scroll;
  }
}
