@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/extra" as extras;
@use "../../stylesheets/00_settings/util" as utils;

.snack {
  padding: utils.rem(14) utils.rem(27) utils.rem(14) utils.rem(23);
  background-color: map-get(colors.$primary-color, white);
  box-shadow: map-get(extras.$box-shadow, default);
  display: flex;
  align-items: center;
  color: map-get(colors.$text-color, rich-black);
  font-style: italic;
  width: utils.rem(345);
  justify-content: space-between;
  $block: &;

  &__title {
    max-width: utils.rem(243);
    font-size: utils.rem(14);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: utils.rem(30);
  }

  &--success {
    #{$block}__icon {
      color: map-get(colors.$primary-color, primary);
    }
  }

  &--critical {
    #{$block}__icon {
      color: map-get(colors.$primary-color, error);
    }
  }

  & + & {
    margin-top: utils.bu(1);
  }
}
