@use "../../../../../stylesheets/00_settings/util" as utils;

.add-mandate-form {

  > p {
    margin-bottom: utils.bu(3);
  }

  .form-action {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .button {
      width: utils.rem(144);
    }
  }
}
