@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.client-card {
  width: utils.rem(380);
  padding: utils.rem(6) utils.rem(14);

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: utils.bu(3.5);

    > svg {
      cursor: pointer;
      color: map-get(colors.$primary-color, secondary);

      &:hover {
        fill-opacity: .7;
        opacity: .7;
      }
    }
  }
}
