
.primary_wrapper {
  display: flex;
  align-items: center;
  
  .primary_label {
    font-size: 20px;
    font-weight: bold;
    min-width: 200px;
    color: #0000DD;

    &.primary {
      color: #FF0000;
    }
  }
}