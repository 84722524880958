@use "../../../stylesheets/00_settings/util" as utils;

.convert-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__message {
    width: 100%;
    margin-top: utils.rem(10);
    margin-bottom: utils.rem(50);
    text-align: left;
    line-height: utils.rem(28);

    span {
      font-weight: bold;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .button {
      width: utils.rem(144);
    }
  }
}
