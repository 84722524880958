// all typography styles
@use "../00_settings/util" as *;

h1 {
  font-size: rem(60);
  font-weight: 400;
}

h2 {
  font-size: rem(48);
  font-weight: 400;
}

h3 {
  font-size: rem(35);
  font-weight: 300;
}

h4 {
  font-size: rem(24);
  font-weight: 400;
}

h5 {
  font-size: rem(20);
  font-weight: 510;
}

h6 {
  font-size: rem(16);
  font-weight: 510;
}

p {
  font-size: rem(16);
  font-weight: 400;
}

a {
  font-size: rem(16);
}

small {
  font-size: rem(12);
}

label {
  font-size: rem(14);
}
