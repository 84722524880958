@use "../../stylesheets/00_settings/color" as color;
@use "../../stylesheets/00_settings/util" as util;
@use "../../stylesheets/00_settings/extra" as extra;
@use "../../stylesheets/01_tools/mixin" as mixin;

$table-row-header-height: util.rem(48);
$table-row-height: util.rem(72);
$table-font-size: util.rem(15);
$table-small-font-size: util.rem(12);
$table-header-actions-width: util.rem(115);

$table-narrow-row-height: util.rem(36);
$table-narrow-header-row-height: util.rem(36);

.table {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border: none;
  background-color: map-get(color.$primary-color, white);
  border-radius: util.rem(8);

  &__header {
    border-radius: util.rem(8);

    tr {
      height: $table-row-header-height;
      color: map-get(color.$primary-color, black);
    }

    &--narrow {

      tr {
        height: $table-narrow-header-row-height;
      }
    }
  }

  tr.table-row {
    transform: scale(1);
  }

  &-row--empty {
    padding-top: util.rem(94);
    padding-bottom: util.rem(74);
    border-radius: util.rem(8);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;

    > h2 {
      font-weight: bold;
      font-size: util.rem(25);
      margin-bottom: util.rem(30);
      text-transform: uppercase;
      color: map-get(color.$primary-color, secondary);
      width: 100%;
      @include mixin.set-font-family('Plantin Light');
    }

    > h6 {
      margin-bottom: 0;
      width: util.rem(335);
    }
  }

  &__body {
    overflow-y: scroll;

    &-action {

      &--danger:hover {
        color: map-get(color.$primary-color, error);
      }
    }

    tr {
      height: $table-row-height;
      background-color: map-get(color.$primary-color, white);

      &--narrow {

        tr {
          height: $table-narrow-row-height;
        }
      }

      &:hover {
        background-color: map-get(color.$hover-color, default);
      }

      td {
        border: none;
      }

      &.table-row {
        background-color: transparent;
        height: $table-row-height;
        transform: none;

        &:nth-child(even) {
          background-color: map-get(color.$hover-color, default);
        }

        &:hover {
          cursor: pointer;
          background-color: map-get(color.$hover-color, default);
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__title {
    color: map-get(color.$primary-color, black);
    margin-bottom: util.bu(2);
    width: 100%;
  }

  &__pagination {
    padding-top: util.rem(28);
    margin-left: auto;
  }
}

th.table-heading {
  font-size: $table-font-size;
  font-weight: 500;
  text-align: left;
  padding: util.bu(1);
  color: map-get(color.$primary-color, black);
  border: none;

  .table-heading__sort-icon {
    display: inline-block;
    margin-left: util.rem(13);
  }

  &:first-child {
    padding-left: util.bu(5);
  }

  &--small {
    font-size: $table-small-font-size;
  }

  &--bordered {
    border: util.rem(2) solid map_get(color.$primary-color, white);
  }

  &--centered {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--hugged {
    width: fit-content;
  }
}

td.table-cell {
  color: map-get(color.$text-color, table);
  padding: util.bu(1);
  font-size: $table-font-size;
  text-align: left;

  &:first-child {
    padding-left: util.bu(5);
  }

  &:last-child {
    padding-right: util.bu(3);
  }

  &--small {
    font-size: $table-small-font-size;
  }

  &--thin {
    width: util.rem(75);
  }

  &--borderless {
    border: none;
  }

  &--centered {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--strong {
    font-weight: 500;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--hugged {
    width: fit-content;
  }

  &--actions {

    > svg {
      margin: auto;
    }
  }

  .input__input-wrapper {
    margin-top: 0;
  }

  &--hover {

    &:hover {
      font-weight: bold;
    }
  }
}
.table-dropdown {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  height: 40px;
}
