@use '../../../stylesheets/00_settings/color' as colors;
@use '../../../stylesheets/00_settings/util' as utils;
@import url('https://fonts.googleapis.com/css?family=Questrial&display=swap');

.calen {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));

  .calendar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    > div > span {
      font-weight: bold;
    }

    > div > button {
      padding: 0 5px;
      --text-opacity: 1;
      color: #a0aec0;
      color: rgba(160, 174, 192, var(--text-opacity));
    }
  }

  .calen-desc {
    color: #de5f28;
  }

  .calendar-table {
    justify-content: center;
    font-size: 15.45px;
    border-collapse: inherit;
    border-spacing: 10px 10px;
    margin-bottom: 40px;

    > thead {
      text-align: center;
      > tr {
        > th {
          width: 36px;
          height: 36px;
        }
      }
    }

    > tbody {
      .pad {
        text-align: center;
        > td {
          width: 36px !important;
          height: 36px;
        }
        > .event-date {
          cursor: pointer;
          background-color: rgb(69, 153, 223);
          width: 36px;
          height: 36px;
          outline: 0;
          border: 0;
          color: #ffff;
          font-family: 'Montserrat', sans-serif;
          border-radius: 50px;
          transition-duration: 0.2s;
        }
        > .current-date {
          background-color: #f00;
          border-radius: 50%;
          color: #fff;
          border: 2px solid #f00;
        }

        > .half-day {
          background-color: #e38e1f;
          border-radius: 50%;
          color: #fff;
          border: 4px solid #6c2dff;
          cursor: pointer;
        }

        > .full-day {
          background-color: #6c2dff;
          border-radius: 50%;
          color: #fff;
          border: 4px solid #6c2dff;
          cursor: pointer;
        }
      }
    }
  }

  .date-info {
    padding: 15px 10px;
    flex-grow: 1;

    .total-days {
      font-size: 20px;
      font-weight: bold;
      color: #bf16bc;
      margin-bottom: 20px;
    }

    .selected-date {
      font-weight: 16px;
      font-weight: bold;
    }
  }
}
