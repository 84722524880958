@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/extra" as extras;
@use "../../stylesheets/00_settings/util" as utils;

.filter-dropdown {
  min-width: utils.rem(130);
  height: utils.rem(45);
  background-color: map-get(colors.$primary-color, white);
  border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .12);
  border-radius: map-get(extras.$border-radius, default);
  padding: utils.bu(2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(map-get(colors.$primary-color, black), .5);
  cursor: pointer;
}
