@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;
@use "../../../stylesheets/01_tools/mixin" as mixins;

.page-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    color: map-get(colors.$primary-color, secondary);
    margin-bottom: utils.bu(3);
    @include mixins.set-font-family('Plantin Light');
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      width: utils.rem(216);
      margin-bottom: utils.bu(3);
    }

    svg {
      color: map-get(colors.$primary-color, secondary);
      cursor: pointer;
    }

    &--bordered {
      border-bottom: utils.rem(1) solid rgba(map-get(colors.$primary-color, secondary), .14);
      margin-bottom: utils.bu(3.5);
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: utils.bu(2) utils.bu(9) utils.bu(2) 0;

    > section {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
