@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.register {
  width: 100%;

  &__password-message {
    color: map-get(colors.$text-color, subtitle);
  }
}
