@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/extra" as extras;
@use "../../stylesheets/00_settings/util" as utils;
@use "../../stylesheets/01_tools/mixin" as mixins;

$checkbox-indicator-size: utils.rem(18);
$checkbox-label-font-size: utils.rem(16);

$main-color: map-get(colors.$primary-color, secondary);
$success-color: map-get(colors.$primary-color, success);
$danger-color: map-get(colors.$primary-color, error);

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  $block: &;

  // hide native input

  &__native {
    display: none;
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $checkbox-indicator-size;
    height: $checkbox-indicator-size;
    border: utils.rem(2) solid $main-color;
    border-radius: map-get(extras.$border-radius, small);
    background-color: transparent;
    color: $main-color;

    @include mixins.transition(background-color, color);

    &--checked {
      background-color: $main-color;
      color: map-get(colors.$primary-color, white);
      border-color: $main-color;
    }
  }

  &__label {
    margin-left: utils.bu(2);
    font-size: $checkbox-label-font-size;
    color: map-get(colors.$text-color, checkbox);
  }

  &--disabled {
    cursor: default;
  }

  &--success {

    #{$block}__label {
      color: $success-color;
      margin-left: utils.bu(1);
    }

    #{$block}__indicator {
      border-color: $success-color;

      &--checked {
        background-color: $success-color;
      }
    }
  }

  &--danger {

    #{$block}__label {
      color: $danger-color;
      margin-left: utils.bu(1);
    }

    #{$block}__indicator {
      border-color: $danger-color;

      &--checked {
        background-color: $danger-color;
      }
    }
  }
}
