@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;
@use "../../../stylesheets/01_tools/mixin" as mixins;

.avatar-layout {
  display: flex;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: utils.bu(4.5);
    background-color: map-get(colors.$primary-color, white);
    margin-right: utils.bu(8);
    height: utils.rem(200);
    width: utils.rem(200);

    > p {
      margin-top: utils.bu(1);
      color: map-get(colors.$primary-color, secondary);
      @include mixins.set-font-family('Plantin Light');
    }

    > small {
      margin-top: utils.bu(1);
      color: map-get(colors.$primary-color, primary);
      @include mixins.set-font-family('Plantin Light');
    }

    &-action {
      position: relative;

      > img,
      > svg {
        color: map-get(colors.$primary-color, secondary);
        object-fit: cover;
        border-radius: 50%;
        width: utils.rem(100);
        height: utils.rem(100);
      }

      .file-upload {

        > svg {
          cursor: pointer;
          color: map-get(colors.$primary-color, primary);
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &__content {
    padding: utils.bu(4.5);
    min-width: utils.rem(672);
    background-color: map-get(colors.$primary-color, white);
  }
}
