@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;
@use "../../../stylesheets/01_tools/mixin" as mixins;

.info-detail {
  width: utils.rem(525);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: utils.bu(1);
  @include mixins.set-font-family('Frutiger Bold');

  dt {
    text-align: left;
    width: 40%;
    color: map-get(colors.$primary-color, secondary);
  }

  dd {
    margin-inline-start: 0;
    width: 30%;
    @include mixins.ellipsis;
  }

  &--editMode {

    dt {
      align-self: center;
      margin-bottom: utils.rem(15);
    }
  }

  &--clickable {
    cursor: pointer;
  }
}
