/* stylelint-disable no-invalid-position-at-import-rule */
@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/util" as utils;
@import "~react-datepicker/dist/react-datepicker.min.css";

.datepicker {
  width: 100%;

  &__calendar {
    box-shadow: 0 utils.rem(4) utils.rem(2) rgba(map-get(colors.$text-color, default), .2);
    border: none;

    .react-datepicker__navigation {
    
      &--years {
      
        &-upcoming {
          transform: rotate(316deg);
          left: 13px;
          top: 0;
        }
        &-upcoming::before {
          border-color: #ccc;
          border-style: solid;
          border-width: 3px 3px 0 0;
          content: "";
          display: block;
          height: 9px;
          position: absolute;
          top: 6px;
          width: 9px;
        }
      
        &-previous {
          transform: rotate(135deg);
          right: 13px;
          top: 0;
        }
        &-previous::before {
          border-color: #ccc;
          border-style: solid;
          border-width: 3px 3px 0 0;
          content: "";
          display: block;
          height: 9px;
          position: absolute;
          top: 6px;
          width: 9px;
        }
      }
     }

    //.react-datepicker__year-read-view {
    //
    //  &--selected-year {
    //    display: none;
    //  }
    //
    //  &--down-arrow {
    //    top: utils.rem(-36);
    //    left: utils.rem(65);
    //  }
    //}

    //.react-datepicker__header {
    //  border: none;
    //  padding-top: 0;
    //
    //  &__dropdown {
    //    background-color: map-get(colors.$primary-color, primary);
    //    color: rgba(map-get(colors.$primary-color, white), .87);
    //    font-weight: 274;
    //    padding-bottom: utils.rem(10);
    //    height: utils.rem(1);
    //    border: none;
    //
    //    &.react-datepicker__year-dropdown,
    //    .react-datepicker__year-dropdown--scrollable {
    //      background-color: map-get(colors.$primary-color, white);
    //      color: rgba(map-get(colors.$text-color, default), .87);
    //      font-weight: 274;
    //
    //      .react-datepicker__year-option:hover {
    //        background-color: rgba(map-get(colors.$primary-color, primary), .12);
    //      }
    //    }
    //  }
    //
    //  &.react-datepicker-year-header,
    //  .react-datepicker__current-month {
    //    padding-top: utils.bu(2);
    //    padding-bottom: utils.rem(10);
    //    font-weight: 274;
    //    background-color: map-get(colors.$primary-color, primary);
    //    color: rgba(map-get(colors.$primary-color, white), .87);
    //    border-radius: utils.rem(3) utils.rem(3) 0 0;
    //
    //    &--hasYearDropdown {
    //      margin-top: utils.rem(1);
    //    }
    //  }
    //
    //  .react-datepicker__day-names {
    //    background-color: map-get(colors.$primary-color, white);
    //    border: none;
    //
    //    .react-datepicker__day-name {
    //      font-weight: 274;
    //      color: map-get(colors.$text-color, dark-gray);
    //    }
    //  }
    //}

    //.react-datepicker__year-wrapper {
    //  align-items: center;
    //  justify-content: center;
    //}
    //
    //.react-datepicker__year-text--selected {
    //  background-color: map-get(colors.$primary-color, primary);
    //}

    &-day {

      &:hover {
        background-color: rgba(map-get(colors.$primary-color, primary), .12);
        color: rgba(map-get(colors.$primary-color, black), .87);
      }

      &--today {
        background-color: map-get(colors.$primary-color, primary);
        font-weight: 350;

        &:hover {
          background-color: map-get(colors.$primary-color, primary);
          font-weight: 400;
        }
      }
    }
  }
}
