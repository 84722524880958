@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/extra" as extras;
@use "../../stylesheets/00_settings/util" as utils;

.radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  $block: &;

  &__label {
    margin-left: utils.bu(1.5);
    color: rgba(map-get(colors.$primary-color, black), .5);
    cursor: pointer;
  }

  &__blip {
    width: utils.rem(13);
    height: utils.rem(13);
    border: utils.rem(1) solid map-get(colors.$primary-color, primary);
    border-radius: map-get(extras.$border-radius, circular);
    display: flex;
    align-items: center;
    justify-content: center;

    &--checked {
      width: utils.rem(9);
      height: utils.rem(9);
      margin: utils.rem(1);
      border-radius: map-get(extras.$border-radius, circular);
      background-color: map-get(colors.$primary-color, primary);
    }
  }

  & + & {
    margin-top: utils.bu(3);
  }

  &--fluid {

    & + & {
      margin-top: 0;
      margin-left: utils.bu(3);
    }
  }

  &--disabled {
    cursor: auto;
  }

  &--danger {

    #{$block}__label {
      color: map-get(colors.$primary-color, error);
    }

    #{$block}__blip {
      border-color: map-get(colors.$primary-color, error);

      &--checked {
        background-color: map-get(colors.$primary-color, error);
      }
    }
  }

  &--success {

    #{$block}__label {
      color: map-get(colors.$primary-color, success);
    }

    #{$block}__blip {
      border-color: map-get(colors.$primary-color, success);

      &--checked {
        background-color: map-get(colors.$primary-color, success);
      }
    }
  }
}
