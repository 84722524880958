@use '../../stylesheets/00_settings/color' as colors;
@use '../../stylesheets/00_settings/util' as utils;

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 6px;

  > li {
    color: map-get(colors.$text-color, title);
    display: flex;
    align-items: center;
  }

  svg {
    color: map-get(colors.$text-color, title);
    height: utils.rem(9);
    width: utils.rem(9);
  }

  > li + svg,
  > svg + li {
    margin-left: utils.bu(1);
  }

  > li:last-child {
    color: map-get(colors.$primary-color, primary);
  }

  p,
  a {
    font-size: utils.rem(10);
    text-transform: uppercase;
  }
}
