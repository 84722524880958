@use "../../stylesheets/00_settings/util" as utils;

.button-group {
  display: flex;
  flex-direction: column;
  $block: &;

  > * + * {
    margin-top: utils.bu(1);
  }

  &--spaced {

    > * + * {
      margin-top: utils.bu(2.5);
    }
  }

  &--fluid {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &#{$block}--spaced {

      > * + * {
        margin-top: 0;
        margin-left: utils.bu(2.5);
      }
    }
  }

  &--centered {
    justify-content: center;
    margin: auto;
  }
}
