@use '../../stylesheets/00_settings/color' as colors;
@use '../../stylesheets/00_settings/util' as utils;
@import url('https://fonts.googleapis.com/css?family=Questrial&display=swap');

.calen {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));

  .calendar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    > div > span {
      font-weight: bold;
    }

    > div > button {
      padding: 0 5px;
      --text-opacity: 1;
      color: #a0aec0;
      color: rgba(160, 174, 192, var(--text-opacity));
    }
  }

  .calendar-table {
    justify-content: center;
    font-size: 15.45px;
    border-collapse: inherit;
    border-spacing: 10px 10px;
    margin-bottom: 40px;

    > thead {
      text-align: center;
      > tr {
        > th {
          width: 36px;
          height: 36px;
        }
      }
    }

    > tbody {
      .pad {
        text-align: center;
        > td {
          width: 36px !important;
          height: 36px;
        }
        > .event-date {
          cursor: pointer;
          background-color: rgb(69, 153, 223);
          width: 36px;
          height: 36px;
          outline: 0;
          border: 0;
          color: #ffff;
          font-family: 'Montserrat', sans-serif;
          border-radius: 50px;
          transition-duration: 0.2s;
        }
        > .current-date {
          background-color: #f00;
          border-radius: 50%;
          color: #fff;
          border: 2px solid #f00;
        }
      }
    }
  }
}

.employee_birthday_wrapper {
  display: flex;
  width: 100%;

  .employee_birthday_content {
    background-color: #4599df;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 12px 12px;
    width: 100%;
  }
}
