@use "../../../../../stylesheets/00_settings/util" as utils;

.contact-history-form {

  .form-row {
    margin-top: utils.bu(1);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    .form-action {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .button {
        width: utils.rem(224);
      }
    }

    &--canAddMandate {
      justify-content: space-between;

      .form-action:first-child {
        justify-content: flex-start;
      }
    }
  }
}
