@use "../../stylesheets/00_settings/util" as *;

$header-height: rem(50);
$snack-bar-vert-offset: bu(-1.5);

.snack-bar {
  position: fixed;
  top: $header-height;
  z-index: 1000;
  right: rem(10);
}
