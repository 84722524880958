@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/config" as config;
@use "../../stylesheets/00_settings/util" as utils;
@use "../../stylesheets/00_settings/extra" as extras;
@use "../../stylesheets/01_tools/mixin" as mixins;

.modal {
  $modal-form: &;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  width: 100%;
  height: 100%;

  &-overlay {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(map-get(colors.$primary-color, black), .3);

    #{$modal-form}-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal-layout {
  width: utils.rem(600);
  min-height: utils.rem(250);
  // overflow-y: scroll;
  border-radius: map-get(extras.$border-radius, large);
  padding: utils.rem(53) utils.rem(43);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: white;
  border: none;
  $block: &;

  &--large {
    width: utils.rem(700);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: utils.rem(30);
    text-align: center;

    &-title {
      padding-top: utils.rem(12);
      margin-right: auto;
      color: map-get(colors.$primary-color, secondary);
      @include mixins.set-font-family('Frutiger Bold');
    }
  }

  &__close {

    svg {
      cursor: pointer;
      color: map-get(colors.$primary-color, secondary);
      @include mixins.transition(opacity);

      &:hover {
        fill-opacity: .7;
        opacity: .7;
      }
    }
  }
}
