@use '../../stylesheets/00_settings/color' as colors;
@use '../../stylesheets/00_settings/util' as utils;
@use '../../stylesheets/01_tools/mixin' as mixins;

$dropdown-menu-min-width: utils.rem(260);
$dropdown-menu-font-size: utils.rem(16);

.dropdown {
  display: flex;

  &__trigger {
    height: 100%;
    cursor: pointer;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1; // place over page content
    background-color: transparent;
  }

  &__popper {
    opacity: 0;
    pointer-events: none;
    @include mixins.transition(opacity);

    &--open {
      opacity: 1;
      pointer-events: all;
      z-index: 2; // place over overlay
    }
  }
}

.dropdown-menu {
  box-shadow: 0 utils.rem(5) utils.rem(5) #c7c8cafd;
  border-radius: utils.rem(5);
  min-width: $dropdown-menu-min-width;
  z-index: 10;

  &__expanded-items {
    padding: utils.bu(2.5) utils.bu(3.5);
  }

  &__item {
    background-color: map-get(colors.$primary-color, white);

    &-suffix {
      margin-left: auto !important;
      color: map-get(colors.$text-color, dark-gray);

      &--expanded {
        transform: rotate(90deg);
      }
    }

    > a,
    > button {
      display: flex;
      width: 100%;
      color: map-get(colors.$primary-color, black);
      padding: utils.rem(20) utils.rem(20) utils.rem(20) utils.rem(22);
      font-size: $dropdown-menu-font-size;
      text-align: left;
      align-items: center;

      svg {
        width: utils.rem(20);
        height: utils.rem(20);
      }

      &:hover {
        background-color: rgba(map-get(colors.$primary-color, primary), .07);
        color: map-get(colors.$primary-color, primary);
      }

      > * + * {
        margin-left: utils.rem(13);
      }
    }
  }
}
