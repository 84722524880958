@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.employee_action {
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
  
    svg {
      cursor: pointer;
      color: map-get(colors.$primary-color, secondary);
  
      & + svg {
        margin-left: utils.bu(3);
      }
  
      &:hover {
        fill-opacity: 0.7;
        opacity: 0.7;
      }
    }
  }
  