@use "../../stylesheets/00_settings/color" as color;
@use "../../stylesheets/01_tools/mixin" as mixins;

.remove-element {

  svg {
    color: map-get(color.$primary-color, secondary);
    cursor: pointer;
    @include mixins.transition(opacity);

    &:hover {
      fill-opacity: .7;
      opacity: .7;
    }
  }
}
