.personnel-pill {
	display: inline-flex;
	align-items: center;
	background-color: #f2f2f2;
	border-radius: 16px;
	padding: 8px 16px;
	margin: 8px;
}

.personnel-pill button {
	margin-left: 8px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: red;
	color: white;
	font-weight: bold;
}

.personnel-list {
	margin-left: 150px;
	width: 500px;
}