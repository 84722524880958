@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/util" as utils;

.dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: utils.bu(5);
  position: relative;

  > .calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: rgb(69, 153, 223);
    border-radius: 10px;
    position: absolute;
    top: -6.5rem;
    right: -4.5rem;
    z-index: 100;
    box-shadow: 0 0.3125rem 1.3125rem rgba(0, 0, 0, 0.2509803922);
    transition: 0.5s;
  }

  > section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: utils.bu(3);

    > article {
      width: 100%;
      margin-right: utils.bu(3);

      > header {
        text-align: center;
        margin-bottom: utils.bu(2);
      }
    }
  }
}
