.alphabet-layout {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.alphabet-layout button {
  background-color: #f0f0f0;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
  
.alphabet-layout button:hover {
  background-color: #e0e0e0;
}

.alphabet-layout button.selected {
  background-color: #c4960c;
  color: #fff;
}