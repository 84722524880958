@use "../../../../../stylesheets/00_settings/util" as utils;

.note-form {

  .form-action {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .button {
      width: utils.rem(144);
    }
  }
}
