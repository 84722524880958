@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/util" as utils;
@use "../../stylesheets/01_tools/mixin" as mixins;

.avatar {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  img {
    width: utils.rem(75);
    height: utils.rem(75);
    object-fit: cover;
    color: map-get(colors.$primary-color, primary);
  }

  svg {
    width: utils.rem(36);
    height: utils.rem(36);
    color: map-get(colors.$primary-color, primary);
  }

  p:first-child {
    margin-left: utils.bu(2);
    color: map-get(colors.$primary-color, primary);
  }

  &__name {
    text-align: left;
  }

  &__subtitle {
    color: map-get(colors.$text-color, table);
    margin-top: utils.bu(1);
    margin-left: utils.bu(2);
    font-size: utils.rem(14);
  }

  &--filled {

    img {
      width: utils.rem(36);
      height: utils.rem(36);
    }

    p:first-child,
    svg {
      color: map-get(colors.$primary-color, secondary);
      @include mixins.set-font-family('Frutiger Bold');
    }
  }
}
