@use "../00_settings/config" as config;

/// Single mixin to change font families
@mixin set-font-family($font-family: default) {
  @if $font-family == default {
    font-family: 'Frutiger Regular', serif;
  } @else {
    font-family: $font-family, serif;
  }
}

// Combines a set of `$properties` into a transition property using `$transition-duration`
@mixin transition($properties...) {
  $transition: '';

  @each $property, $index in $properties {
    $transition: unquote($transition) + $property config.$transition-duration;

    @if index($properties, $property) < length($properties) {
      $transition: $transition + ', ';
    }
  }

  transition: $transition;
}

// Helper to output placeholder styling for each browser.
// Accepts a block of properties to add to the placeholders.
@mixin placeholder($color) {

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    @content;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    @content;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
    @content;
  }
}

///  breakpoint mixins based on breakpoint definitions, accepting content blocks
///  to be used for responsive design
@mixin breakpoint($bp-name) {
  $min-width: '';

  @if map-has-key(config.$breakpoints, $bp-name) {
    $min-width: map-get(config.$breakpoints, $bp-name);
  } @else if (type_of($bp-name) == number) {
    $min-width: $bp-name;
  }

  $min-width-ems: ($min-width / config.$base-font-size) * 1em;

  @media screen and (min-width: $min-width-ems) {
    @content;
  }
}

@mixin ellipsis() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
