@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/util" as utils;
@use "../../stylesheets/01_tools/mixin" as mixins;

.radio-group {
  width: 100%;
  $block: &;

  &__label {
    color: map-get(colors.$text-color, black);
    padding-left: utils.bu(1);
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__description {
    font-size: utils.rem(14);
    color: rgba(map-get(colors.$primary-color, black), .87);
    margin-top: utils.bu(2);
    margin-bottom: utils.rem(30);
    font-weight: 300;
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__buttons {
    margin-top: utils.rem(30);
    padding-left: utils.bu(1);
    display: flex;
    flex-direction: column;
  }

  &__error {
    display: block;
    color: map-get(colors.$text-color, error);
    margin-top: utils.bu(2);
    padding-left: utils.bu(1);
  }

  &--fluid {

    #{$block}__buttons {
      align-items: center;
      flex-direction: row;

      .radio-button + .radio-button {
        margin-top: 0;
        margin-left: utils.bu(2);
      }
    }
  }
}
