@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/util" as utils;
@use "../../stylesheets/01_tools/mixin" as mixins;

.details-card {
  padding: utils.bu(4.5);
  width: utils.rem(672);
  background-color: map-get(colors.$primary-color, white);
  $block: &;

  &__header {
    align-items: center;
    position: relative;

    &--centered {

      #{$block}__title {
        text-align: center;
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      cursor: pointer;
      color: map-get(colors.$primary-color, secondary);

      & + svg {
        margin-left: utils.bu(3);
      }

      &:hover {
        fill-opacity: .7;
        opacity: .7;
      }
    }
  }

  &__title {
    text-align: left;
    color: map-get(colors.$primary-color, secondary);
    font-size: utils.rem(28);
    margin-bottom: utils.bu(5);
    @include mixins.set-font-family('Plantin Light');
  }

  &__content {

    &--scrollable {
      max-height: utils.rem(220);
      overflow-y: scroll;
    }
  }

  &__empty {
    width: utils.rem(528);
    text-align: center;
    margin-bottom: utils.bu(4);
  }

  &--fitted {
    width: fit-content;
  }

  &--fittedHeight {
    height: fit-content;
  }

  &--small {
    width: utils.rem(550);
  }
}
