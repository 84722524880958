@use "../../../../stylesheets/00_settings/color" as colors;
@use "../../../../stylesheets/00_settings/util" as utils;

.tombstone-form {
  width: 100%;
  display: flex;
  gap: utils.bu(8);

  .info-detail {
    width: 100%;

    dt {
      width: 40%;
    }
  }

  &__uploads {
    display: flex;
    flex-direction: column;
    gap: utils.bu(3);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: utils.bu(5);

    > .button {
      width: utils.rem(142);
    }
  }

  .personnel-pill {
    display: inline-flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 16px;
    padding: 8px 16px;
    margin: 8px;
  }
  
  .personnel-pill button {
    margin-left: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-weight: bold;
  }
  
  .personnel-list {
    margin-left: 150px;
    width: 500px;
  }
}
