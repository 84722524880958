@use "../../../../stylesheets/00_settings/color" as colors;
@use "../../../../stylesheets/00_settings/util" as utils;

.precedent-form {
  width: 100%;
  display: flex;
  gap: utils.bu(8);

  .info-detail {
    width: 100%;

    dt {
      width: 40%;
    }
  }

  &__uploads {
    display: flex;
    flex-direction: column;
    gap: utils.bu(3);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: utils.bu(5);

    > .button {
      width: utils.rem(142);
    }
  }
}

.uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &__name {
    display: flex;
    align-items: center;

    svg {
      color: map-get(colors.$primary-color, primary);
      margin-right: utils.rem(14);
    }
  }
}
