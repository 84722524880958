@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.expense-claim-form {
  width: 100%;
  display: flex;
  gap: utils.bu(8);

  &__uploads {
    display: flex;
    flex-direction: column;
    gap: utils.bu(3);
  }
}

.uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &__name {
    display: flex;
    align-items: center;

    svg {
      color: map-get(colors.$primary-color, primary);
      margin-right: utils.rem(14);
    }
  }
}
