@font-face {
  font-family: 'Frutiger Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Frutiger.ttf') format('truetype');
}

@font-face {
  font-family: 'Frutiger Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Frutiger_bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Plantin Light';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Plantin Light.otf') format('otf');
}

@font-face {
  font-family: 'Plantin Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Plantin Bold Condensed.otf') format('otf');
}
