@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/extra" as extra;
@use "../../stylesheets/00_settings/util" as utils;
@use "../../stylesheets/01_tools/mixin" as mixins;

.input {
  width: 100%;

  &__label {
    text-align: left;
    padding-left: utils.bu(2);
    margin-bottom: utils.bu(1);
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: map-get(colors.$primary-color, secondary);
    @include mixins.set-font-family('Frutiger Bold');

    > small {
      cursor: pointer;
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__wrapper {
    border: utils.rem(1) solid map-get(colors.$primary-color, primary);
    border-radius: map-get(extra.$border-radius, default);
    padding: utils.bu(2);

    &--disabled {
      background-color: map-get(colors.$text-color, disabled);

      input {
        background-color: map-get(colors.$text-color, disabled);
      }
    }

    input {
      width: 100%;
      border: none;
      color: map-get(colors.$text-color, input);
      outline: none;
      font-size: utils.rem(16);
      @include mixins.transition(color);
      @include mixins.placeholder(map-get(colors.$text-color, secondary)) {
        opacity: .3;
      }

      &:focus-within,
      &:active {
        outline: none;
        border: none;
      }
    }
  }

  &__error {
    height: utils.rem(15);
    text-align: left;
    margin-top: utils.bu(1);
    padding-left: utils.bu(2);
    color: map-get(colors.$primary-color, error);
  }
}
