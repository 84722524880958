@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;

.clear-filters {
  cursor: pointer;
  width: utils.rem(125);
  display: flex;
  align-items: center;
  color: map-get(colors.$primary-color, primary);
  font-size: utils.rem(14);

  &__title {
    width: utils.rem(95);
    margin-left: utils.rem(10);
  }

  > svg {
    width: utils.rem(17);
    height: utils.rem(17);
  }
}
