@use "../../stylesheets/00_settings/color" as colors;
@use "../../stylesheets/00_settings/util" as utils;
@use "../../stylesheets/01_tools/mixin" as mixins;

.sidebar-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
  margin-bottom: utils.bu(3);
  color: map-get(colors.$primary-color, white);
  $block: &;

  svg:last-child {
    rotate: 180deg;
  }

  > a,
  > header {
    padding: utils.rem(10) utils.bu(2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__title {
    display: flex;
    align-items: center;

    p {
      margin-top: utils.bu(.5);
      padding-left: utils.bu(1.5);
      color: transparent;
    }
  }

  &--open {

    svg:last-child {
      rotate: 360deg;
    }
  }

  &--inner {
    margin-bottom: 0;

    & + & {
      margin-top: utils.bu(1.5);
    }

    p {
      padding-left: utils.bu(5);
    }
  }

  &--innerInner {

    #{$block}--inner {

      p {
        padding-left: utils.bu(7);
      }
    }
  }
}

.sidebar {
  width: utils.rem(50);
  background-color: map-get(colors.$primary-color, secondary);
  height: 100%;
  padding: utils.bu(3.5) 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  $block: &;
  @include mixins.transition(width);

  &__title {
    color: transparent;
    text-transform: uppercase;
    font-size: utils.rem(10);
    margin-bottom: utils.bu(1.5);
    margin-left: utils.bu(2);
  }

  &__section {
    width: 100%;
    color: map-get(colors.$primary-color, white);
    padding: utils.bu(1.5) 0;

    > li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: utils.rem(14);
    }
  }

  &__logout {
    margin-top: auto;
    width: 100%;
    margin-bottom: utils.bu(1.5);
    color: map-get(colors.$primary-color, white);

    > small {
      color: transparent;
      padding-left: utils.bu(2);
      margin-top: utils.bu(1.5);
    }
  }

  &:hover {
    overflow: auto;
    width: utils.rem(274);

    #{$block}__title {
      color: map-get(colors.$primary-color, white);
      transition: color 1s;
    }

    #{$block}__logout {

      > small {
        color: map-get(colors.$primary-color, white);
        transition: color 1s;
      }
    }

    .sidebar-item__title p {
      color: map-get(colors.$primary-color, white);
      transition: color 1s;
    }
  }
}
