@use "../../../../stylesheets/00_settings/color" as colors;
@use "../../../../stylesheets/00_settings/extra" as extra;
@use "../../../../stylesheets/00_settings/util" as utils;
@use "../../../../stylesheets/01_tools/mixin" as mixins;

.note {
  width: utils.rem(525);
  text-align: left;

  &__content {
    font-size: utils.rem(12);
    border-radius: map-get(extra.$border-radius, medium);
    background-color: map-get(colors.$background-color, note);
    padding: utils.bu(2);
    margin-bottom: utils.bu(1);
    color: rgba(map-get(colors.$primary-color, black), .5);
    white-space: pre;
  }

  > small {
    font-size: utils.rem(10);
    padding-left: utils.bu(2);
    color: map-get(colors.$text-color, byline);
  }

  & + & {
    margin-top: utils.bu(3);
  }
}
