@use "../../../stylesheets/00_settings/color" as colors;
@use "../../../stylesheets/00_settings/util" as utils;
@use "../../../stylesheets/01_tools/mixin" as mixins;

.employee-detail {
  width: 100%;

  hr {
    border-color: rgba(map-get(colors.$primary-color, secondary), .14);
  }

  &__accordion {
    border-bottom: utils.rem(1) solid rgba(map-get(colors.$primary-color, secondary), .14);
    padding: utils.bu(3) 0;

    > header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h4 {
        color: map-get(colors.$primary-color, secondary);
        @include mixins.set-font-family('Plantin Light');
      }

      > span {
        display: flex;
        align-items: center;
        gap: utils.bu(1);
        color: map-get(colors.$primary-color, secondary);
      }
    }

    > main {
      height: 0;
      padding: 0;
      content-visibility: hidden;
      @include mixins.transition(padding, height, content-visibility);
    }

    &--open {

      > main {
        padding-top: utils.bu(2);
        height: 100%;
        content-visibility: visible;
      }
    }
  }
}

.tabs {

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: utils.bu(2) 0;
    gap: utils.bu(2);

    > section {
      display: flex;
      align-items: center;
    }

    .button {
      width: utils.rem(220);
    }
  }
}

.tab {
  cursor: pointer;
  color: map-get(colors.$primary-color, secondary);
  border-top-right-radius: utils.rem(8);
  border-top-left-radius: utils.rem(8);
  padding: utils.bu(2);
  @include mixins.set-font-family('Plantin Light');

  > p {
    font-size: utils.rem(20);
  }

  &--active {
    background-color: map-get(colors.$primary-color, primary);
    color: map-get(colors.$primary-color, white);
  }
}
